import React, { Fragment } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import _map from 'lodash/map';
import Img from 'gatsby-image';
import SEO from '../components/SEO';
import styled from 'styled-components';
import {
  PageWrapper,
  PageInner,
  PageTitle,
  PostLink, 
  Banner,
  Grid2Col,
  Text2Col,
  ArrowUpLink,
  ArrowUpIcon,
  ArrowDownLink,
  ArrowDownIcon,
  NavigationBottom, 
  Spacer,
} from '../components/Elements';

const blablaQuery = graphql`
  {
   
    banner: file(relativePath: { eq: "bandeau_ressources.png" }) {
        childImageSharp {
           fluid( maxWidth:1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }

    page: datoCmsPageRessource {
      titre
      contenu
      ressource {
        titre
        id
        liens {
          ... on DatoCmsLienRessource {
            model {
              apiKey
            }
            id
            url
          }
        }

      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;

const RessourceWrapper = styled.div`
  margin-bottom:2rem;
  & a:hover {text-decoration:underline;}
`

const RessourceTitle = styled.div`
 font-weight:bold;
`

export default function RessourcesPage() {
  const data = useStaticQuery(blablaQuery);
  const { titre, contenu, seoMetaTags, ressource } = data.page;

  return (
    <Fragment>
      <SEO meta={seoMetaTags} />
      <PageWrapper>
       {/* <Banner>
          <Img fluid={data.banner.childImageSharp.fluid} />
        </Banner>*/}
        <PageInner>
        
          
              <PageTitle>{titre}</PageTitle>
              {contenu && <>
              <Text2Col dangerouslySetInnerHTML={{ __html: contenu }} />
              <Spacer/></>
    }
              {ressource.map(item => (

                <RessourceWrapper key={item.id}>
                  <RessourceTitle>{item.titre}</RessourceTitle>
                  {item.liens.map(block => (
                    <div key={block.id}>
                      {block.model.apiKey === 'lien_ressource' && <a href={block.url} taget="_blank" >{block.url}</a>}
                    </div>
                  ))}
                </RessourceWrapper>
              ))}
          
        
      {/*  <NavigationBottom>
           <ArrowUpLink to="/projets" title="Projets">
             Projets
              <ArrowUpIcon title="Aller à la page Projets" />
            </ArrowUpLink>
            <ArrowDownLink to="/contact" title="Contact">
              <ArrowDownIcon title="Aller à la page Contact" />
              Contact
            </ArrowDownLink>
          </NavigationBottom> */}

        </PageInner>
      </PageWrapper>
    </Fragment>
  );
}
